import { createElement, forwardRef } from 'react'; // Import forwardRef along with createElement

import cn from '@/lib/clsxm';

import { CONTAINER_TAG_TYPE, Props } from '@/components/@base/container/type';

const Container = forwardRef<HTMLDivElement | HTMLElement, Props>(
  ({ className, center = false, type = CONTAINER_TAG_TYPE.DIV, children, ...props }, ref) => {
    return createElement(
      type,
      {
        ref,
        className: cn(center && 'flex items-center justify-center', className),
        ...props,
      },
      children,
    );
  },
);

export default Container;
