const localization = {
  zhaket: 'ژاکت',
  zhaketIsRunning: 'ژاکت داره کار میکنه',
  search: 'جستجو',
  bundles: 'باندل ها',
  productPreview: 'پیشنمایش ',
  addToCart: 'افزودن به سبد خرید',
  freeDownload: 'دانلود رایگان',
  lookForIncome: 'دنبال کسب در آمد هستید؟',
  cooperateWithUs: 'در ژاکِت توسعه دهنده شوید',
  moreInfo: 'اطلاعات بیشتر',
  register: 'ثبت نام',
  viewTheTopPlugins: 'مشاهده برترین',
  free: 'رایگان',
  unavailable: 'ناموجود',
  viewAll: 'مشاهده همه',
  IRCurrency: 'تومان',
  newestProducts: 'جدیدترین محصولات',
  newestIranianProducts: 'جدیدترین محصولات',
  message: 'پیام',
  loading: 'در حال بارگذاری',
  newest: 'جدید‌ترین',
  topSale: 'پرفروش‌ترین',
  specialSale: 'فروش ویژه',
  mostUpToDate: 'به‌روز ‌ترین',
  home: 'خانه',
  archive: 'آرشیو',
  categories: 'دسته‌بندی‌ها',
  enamad: 'نماد اعتماد',
  timeUnits: {
    minute: 'دقیقه',
    second: 'ثانیه',
  },
  outOf: 'از',
  vote: 'رأی',
  sale: 'فروش',
  showMore: 'نمایش بیشتر',
  seeMore: 'مشاهده بیشتر',
  faq: 'سوالات متداول',
  vendor: 'توسعه دهنده',
  customer: 'خریدار',
  administrator: 'ادمین',
  support_team: 'تیم پشتیبانی',
  cart: 'سبد خرید',
  loginToZhaket: 'ورود به ژاکت',
  products: 'محصولات',
  themeDemos: 'دموهای قالب',
  seeAllResult: 'مشاهده همه نتایج',
  notfoundAnyProduct: 'سبد خرید شما خالی است!',
  notfoundAnyDemo: 'دموی محصول یافت نشد',
  rate: 'امتیاز',
  next: 'بعدی',
  previous: 'قبلی',
  demos: 'دمو‌ها',
  somethingWentWrong: 'خطایی رخ داده است!',
  saman: 'درگاه سامان',
  zarinpal: 'درگاه زرین پال',
  pay: 'درگاه پی',
  pasargad_api: 'درگاه پاسارگاد',
  pasargad: 'درگاه پاسارگاد',
  all: 'همه',
  personal: 'شخصی',
  ecommerceWoocommerce: 'فروشگاهی',
  corporate: 'شرکتی',
  nope: 'خیر',
  viewProducts: ' مشاهده محصولات',
  lastUpdatedIranianProducts: 'به‌روز ‌ترین محصولات ایرانی، کیفیت و نوآوری داخلی',
  onSaleProducts: 'پرتخفیف های ژاکت، همین حالا بخرید!',
  mostSalesDemos: 'دموهای پرفروش، محبوب‌ترین انتخاب‌ها',
  developer: 'توسعه دهنده',
  otherDemos: 'سایر دمو‌ها',
  notFoundAnyResult: 'هیچ نتیجه ای یافت نشد!',
  tryAgainOrUseOtherFilters: 'دوباره تلاش کنید یا از فیلتر های دیگر استفاده کنید',
  copyDiscountCode: 'کپی کد',
  copied: 'کپی شد',
  discountCode: 'کد تخفیف',
  rateToYourPurchases: 'به محصولات خریداری شده، امتیاز دهید',
  rateToYourPurchase: 'به محصول خریداری شده، امتیاز دهید',
  yourRateIsUseful: 'با ثبت امتیاز، به بهبود محصولات و خدمات کمک کنید.',
  laterPlease: 'بعدا لطفاً !',
  giveRate: 'ثبت امتیاز',
  yourRate: 'امتیاز شما',
  viewConditions: ' مشاهده شرایط',
  cookiePermission:
    'ما از کوکی‌ها برای ارائه خدمات بهتر، تجزیه و تحلیل رفتار کاربران، و بهبود تجربه شما استفاده می‌کنیم. لطفاً با تائید این مورد ما را در مسیر بهبود تجربه کاربری یاری فرمائید.',
  reject: 'رد کردن',
  acceptAll: 'پذیرفتن همه',
};

export const pageLevelLocalization = {
  home: {
    title: 'ژاکت',
    metaTitle: 'ژاکت; مرجع قالب و افزونه وردپرس فارسی و اورجینال',
    metaDescription:
      'محصولات اورجینال، ایرانی و فارسی سازی شده وردپرس را با ۶ ماه پشتیبانی رایگان، تضمین اصالت و کیفیت ژاکت و ۶ ماه ضمانت بازگشت وجه خریداری کنید.',
    description:
      'محصولات اورجینال، ایرانی و فارسی سازی شده وردپرس را با ۶ ماه پشتیبانی رایگان، تضمین اصالت و کیفیت ژاکت و ۶ ماه ضمانت بازگشت وجه خریداری کنید.',
    homeHeading: 'ژاکت، معتبرترین مارکت تخصصی قالب و افزونه وردپرس',
    homeSubtitle: 'راهکار برتر راه‌اندازی و توسعه وب‌سایت',
    moneyBackGuarantee: 'ضمانت بازگشت وجه',
    permanentSupport: 'پشتیبانی حرفه ای',
    automaticUpdate: 'به‌روزرسانی خودکـــار',
    zhkWorld: 'ژاکت، انتخاب‌های متنوع',
    bestOfWeek: 'توسعه‌دهنده برتر هفته',
    onlineCourses: 'دوره‌های آنلاین',
    moreThan: 'بیش از',
    thousandOfProducts: 'با هزاران قالب و افزونه',
    bestQualityAndMoreVariety: ' باکیفیت و متنوع وردپرس',
    searchPlaceHolder: 'مثلا قالب فروشگاه',
    bestEcommerceTemplates: 'قالب های برتر فروشگاهی',
    settingUpModernAndSpecialStore: 'راه اندازی یک فروشگاه مدرن و خاص',
    iranianProducts: 'محصولات ایرانی',
    bestSupport: 'پشتیبانی درجه یک',
    iranianTemplate: 'قالب ایرانی',
    iranianPlugin: 'افزونه ایرانی',
    latestBlogs: 'آخرین مطالب وبلاگ، همیشه به‌روز باشید',
    viewBlogs: 'مشاهده بلاگ',
    studyTime: ' دقیقه زمان مطالعه',
    searchAndFindBestProducts: 'جستجو کنید، بهترین محصولات را بیابید و سایت خود را مجهز کنید.',
    moreThanThousandProductsAvailable: 'بیش از هزار محصول متنوع در دسترس شماست!',
    fantasticOffers: 'تخفیف‌های شگفت انگیز در باندل‌های منتخب',
  },
  layout: {
    whyBuyFromZhaket: 'چرا از ژاکت بخرم؟',
    bestPluginAndThemeProvider: 'معتبرترین سامانه خرید افزونه و پلاگین فارسی',
    usersSatisfaction: 'رضایت خرید کاربران',
    differentProducts: 'محصول مختلف',
    refundGuarantee: 'ضمانت بازگشت وجه',
    securityAndUpdate: 'امنیت و بروزرسانی',
    zhaketNewsLetter: 'خبرنامه ژاکت',
    enterYourEmail: 'ایمیل خود را وارد کنید',
    askIfYouHaveAnyQuestion: 'سوالی دارید ؟ بپرسید',
    registerThenSendTicket: 'ابتدا عضو شوید و سپس تیکت بفرستید',
    aboutUs:
      'مرجع وردپرس فارسی و رهبر بازار اولین پلتفرم ارائه دهنده خدمات و محصولات دیجیتال در ایران که با گردهم آوری منابع انسانی توانمند و برجسته بدنبال خلق ارزش برای ذینفعان خود می باشد.',
    aboutUs2:
      ' ژاکت دارای 6 فاز توسعه در سمت محصول با تیم قدرتمند فنی و تیم کارکشته و با تجربه بازاریابی برای افزایش سهم بازار حداکثری خود است.',
    searchAtZhaket: 'جستجو در ژاکت',
    policy: 'تمامی حقوق برای ژاکت محفوظ است',
    forExampleWoodmart: 'مثلا قالب وودمارت',
    dashboard: 'پیشخوان',
    profileCompletion: 'تکمیل پروفایل',
    sendTicket: 'ثبت تیکت',
    downloads: 'دانلودها',
    editProfile: 'ویرایش حساب',
    logOut: 'خروج از حساب',
    loginOrRegister: 'ورود | ثبت‌نام',
    zhkSuggestions: 'پیشنهاد ژاکت',
    favouriteSearch: 'جستجو‌های محبوب',
  },
  archive: {
    description:
      'این افزودنی یکی از محبوب‌ترین افزودنی‌های المنتوری با بیش از ۹۰ هزار نصب فعال در مخزن وردپرس به شمار می‌رود این افزودنیاختیار قراردادن',
    title: 'قالب فروشگاهی وردپرس',
    showFilter: 'مشاهده فیلترها',
    sort: 'مرتب‌سازی :',
    themeDemos: 'دموهای قالب',
    filter: {
      productCategories: 'دسته بندی محصولات',
      live: 'جستجوی دمو',
      isSubscription: 'محصولات اشتراکی',
      unavailableProducts: 'نمایش محصولات ناموجود',
      medalBased: 'بر اساس مدال',
      mainProducts: 'محصولات اصلی',
      original: 'original',
      featured: 'featured',
      iranian: 'iranian',
      licensed: 'licensed',
      specialProducts: 'محصولات ویژه',
      iranianProducts: 'محصولات ایرانی',
      zhaketLicencedProducts: 'دارای لایسنس ژاکت گارد',
      applyFilters: 'اعمال فیلترها',
      removeFilters: 'حذف فیلترهای فعال',
      demoTooltip: 'امکان انتخاب دمو متناسب با کسب و کار',
    },
    searchResult: 'نتایج جستجو',
  },
  login: {
    title: 'ژاکت',
    description:
      'محصولات اورجینال، ایرانی و فارسی سازی شده وردپرس را با ۶ ماه پشتیبانی رایگان، تضمین اصالت و کیفیت ژاکت و ۶ ماه ضمانت بازگشت وجه خریداری کنید.',
    loginAndRegister: 'ورود / ثبت نام',
    loginOrRegister: 'ورود یا ثبت نام',
    emailOrPhoneNumber: 'ایمیل یا شماره تماس',
    policy: 'نکات امنیتی',
    recommendedBrowsers:
      'لطفا از مرورگرهای مطمئن و بروز مانند گوگل کروم و فایرفاکس استفاده کنید.',
    checkingPassword: 'لطفا کلمه عبور خود را در فواصل زمانی کوتاه حتما بررسی و تغییر دهید.',
    zhkPreventUsingEmail:
      'ژاکت  اطلاعات شما را از طریق ایمیل درخواست نمیکند. در صورت رخداد به ژاکت اطلاع دهید.',
    passwordRecovery: 'فراموشی رمز عبور',
    otpVerification: 'ارسال',
    enterOtp: 'کد ارسال شده را وارد کنید',
    codeSentToThis: (num: string) => `کد به شماره ${num} ارسال شد.`,
    resendCode: 'ارسال مجدد کد ',
    editPhone: 'تغییر شماره',
    loginByPass: 'ورود با کلمه عبور',
    loginByCode: 'ورود با کد یکبارمصرف',
    password: 'کلمه عبور',
    enterPassword: 'کلمه عبور را وارد کنید',
    enterPasswordAgain: 'کلمه عبور را مجددا وارد کنید',
    enterPhoneNumberOrEmail: 'ایمیل یا شماره همراه خود را وارد نمایید',
    login: 'ورود',
    sendVerificationCode: 'ارسال کد تایید',
    forgetPassword: 'فراموشی رمز عبور',
    confirmPassword: 'تکرار رمز عبور',
    enterYourPhoneForRegister: 'جهت ثبت نام شماره موبایل خود را واردنمایید',
    enterPhone: 'شماره همراه خود را وارد نمایید',
    enterEmail: 'ایمیل خود را وارد نمایید',
    youHaveToRegister: 'ابتدا باید ثبت نام کنید',
    enterYourPass: 'کلمه عبور',
    email: 'ایمیل',
    phone: 'شماره همراه',
    emailOrPhone: 'شماره همراه یا ایمیل',
    enterTwoFactorCode: 'کد دو عاملی',
    passwordShouldBeAtleastSixChar: 'کلمه عبور می بایست حداقل ۶ کاراکتر باشد.',
    enterYourNewPassword: 'کلمه عبور جدید را وارد کنید',
  },
  product: {
    suggestedAlternativeProduct: 'محصول پیشنهادی جایگزین',
    suggestedAlternativeProducts: 'محصولات پیشنهادی جایگزین',
    specialDiscount: 'تخفیف ویژه',
    remainingAmount: (count: number) => `تعداد باقی مانده: ${count} عدد`,
    averageRate: 'میانگین امتیاز',
    successfulSale: 'فروش موفق',
    successfulDownload: 'دانلود موفق',
    content: 'توضیحات محصول',
    developerDiscountCodes: 'کدهای تخفیف توسعه دهنده',
    comments: 'دیدگاه‌ها',
    support: 'پشتیبانی',
    changelog: 'تغییرات قالب',
    changelogTitle: ' آخرین لیست تغییرات محصول',
    updates: 'بار بروزرسانی',
    versionChanges: 'تغییرات نسخه',
    addComment: 'افزودن دیدگاه',
    addYourComment: 'دیدگاه خود را بنویسید',
    reply: 'پاسخ',
    highlights: 'ویژگی‌های کلیدی',
    releaseDate: 'تاریخ انتشار',
    version: 'نسخه',
    updateDate: 'تاریخ بروزرسانی',
    otherVendorProducts: 'سایر محصولات',
    relatedProducts: 'محصولات مشابه',
    tags: 'برچسب‌ها',
    loginToComment: 'برای ثبت دیدگاه نیاز است وارد شوید',
    addCommentSuccess: 'دیدگاه با موفقیت ثبت شد',
    allComments: 'همه دیدگاه‌ها',
    justBuyersComments: 'فقط خریدارن',
    loginOrRegister: 'ورود/ثبت‌نام',
    saveComment: 'ثبت دیدگاه',
    buyProductToGetSupport: 'برای استفاده از پشتیبانی باید این محصول را خریداری کنید',
    contactVendorByTicket: 'از بخش تیکت میتوانید به راحتی با فروشنده در ارتباط باشید',
    productAddedToCart: 'محصول به سبد خرید اضافه شد',
    twelveMonthSupport: 'پشتیبانی ۱۲ ماهه',
    installTemplateByVendor: 'نصب قالب توسط',
    installationConditions: 'شرایط نصب',
    payableAmount: 'مبلغ قابل پرداخت',
    payable: 'قابل پرداخت',
    return: 'بازگشت به ژاکت',
    continueShopping: 'ادامه خرید',
    buyWithoutSupport: 'خرید بدون پشتیبانی',
    affiliateTitlePart1: 'با سیستم همکاری در فروش ژاکت،',
    affiliateTitlePart2: 'از فروش ',
    affiliateTitlePart3: ' کسب درآمد کنید',
    startAffiliate: 'شروع درآمد',
    getAffiliateLink: 'دریافت لینک همکاری',
    linkIsCopied: 'لینک کپی شد.',
    moreInfo: 'اطلاعات بیشتر',
    earnPerProduct: (price) => `به ازای هر فروش این محصول، ${price} تومان درآمد داشته باشید`,
    profitLabel: 'تومان سود',
    defaultHighlight1: '۶ ماه پشتیبانی رایگان',
    defaultHighlight2: 'دسترسی دائمی به فایل محصول',
    defaultHighlight3: 'تضمین اصالت و کیفیت توسط ژاکت',
    defaultHighlight4: 'دسترسی مادام العمر به فایل و لایسنس محصول',
    defaultHighlight5: '۶ ماه ضمانت بازگشت وجه',
    defaultHighlight6: 'بروزرسانی مداوم و رایگان',
    oneYearSubscriptionHighlight1: 'دسترسی یک ساله به فایل و لایسنس محصول',
    oneYearSubscriptionHighlight2: '6 ماه پشتیبانی کاملا رایگان',
    oneYearSubscriptionHighlight3: 'تضمین کیفیت و اصالت ژاکت',
    oneYearSubscriptionHighlight4: '6 ماه ضمانت بازگشت وجه',
    oneYearSubscriptionHighlight5: 'یکسال بروزرسانی رایگان',
    lifetimeSubscriptionHighlight1: 'دسترسی همیشگی به فایل و لایسنس محصول',
    lifetimeSubscriptionHighlight2: '6 ماه پشتیبانی کاملا رایگان',
    lifetimeSubscriptionHighlight3: 'تضمین کیفیت و اصالت ژاکت',
    lifetimeSubscriptionHighlight4: '6 ماه ضمانت بازگشت وجه',
    lifetimeSubscriptionHighlight5: 'بروزرسانی رایگان دائمی',
    GetOneYearSubscription: 'تهیه اشتراک یکساله',
    GetLifetimeSubscription: 'تهیه اشتراک مادام العمر',
    seeAllChanges: 'مشاهده همه تغییرات',
    seeAllComments: 'مشاهده همه نظرات',
    installationRulesTitle: 'نصب قالب صرفا در شرایط زیر انجام میشود:',
    installationRules: [
      'نصب فقط از طریق بسته نصبی انجام می شود و تمامی محتویات و اطلاعات قبلی در این فرایند باید حذف گردد.',
      'پس از انتخاب نصب قالب، تیکتی برای شما ارسال خواهد شد که لازم است دسترسی های هاست خود را جهت نصب برای کارشناسان ژاکت در همان تیکت ارسال کنید.',
      'نصب قالب در کمتر از 4 ساعت پس از خرید برای شما انجام خواهد شد. (نصب قالب برای سفارشات ثبت شده از ساعت ۲۲ الی ۹ صبح، بعد از ساعت ۹ صبح انجام خواهد شد)',
      'در صورتی که فرآیند نصب برای شما انجام شده باشد و سفارش شما به هر دلیل شامل استرداد شود، امکان استرداد هزینه نصب قالب وجود ندارد.',
      'در صورتی که مشکلات هاست شما مانع از نصب قالب شود، موارد جهت پیگیری از شرکت هاستینگ به شما اعلام خواهد شد. بدیهی است در این شرایط هزینه نصب قالب شامل استرداد نخواهد شد.',
      'در صورتی که به تشخیص کارشناسان ژاکت به هر دلیل هزینه نصب قالب شامل استرداد شود، قالب خریداری شده شامل استرداد نخواهد بود .',
      'انصراف کاربر از نصب قالب توسط ژاکت، شامل استرداد نخواهد شد.',
    ],
    share: 'اشتراک',
    like: 'علاقه مندی',
    dislike: 'حذف علاقه مندی',
    likeSuccessMessage: 'به علاقه مندی ها اضافه شد.',
    unlikeSuccessMessage: 'از علاقه مندی ها حذف شد.',
    understood: 'متوجه شدم',
    bundlePack: 'باندل پک',
    buyBundlePackOffer: (count) =>
      `این محصول را با ${count} محصول منتخب دیگر با تخفیف ویژه دربسته شگفت انگیز ژاکت دریافت کنید.`,
    view: 'مشاهده',
    prerequisiteProducts: 'محصولات زیر پیش نیاز استفاده از این محصول می باشد',
    prerequisiteTooltip: 'عملکرد سایت آماده وابسته به این محصولات است.',
    ticketSupport: 'پشتیبانی تیکت',
    sendTicket: 'ارسال تیکت',
    openProductComments: 'مشاهده دیدگاه ها',
    pendingComment: 'در انتظار بررسی',
    giveRateToProduct: 'به محصول امتیاز دهید',
    rateSavedSuccessfully: 'امتیاز شما با موفقیت ثبت شد.',
    pleaseSelectARate: 'لطفا بین عدد ۱ تا ۵ امتیاز دهید',
    saveRate: 'ثبت امتیاز',
    yourPurchaseHistory: 'سابقه خرید این محصول توسط شما',
    supportExtension: 'تمدید پشتیبانی',
    supportExpired: '',
    supportRemainingDays: (count) => `${count} روز مانده به اتمام پشتیبانی`,
    productDownload: 'دانلود محصول',
    noDomain: 'بدون دامنه',
    bundleCommentsMessage:
      'محصول فوق یک بسته شگفت انگیز ژاکت با تخفیف ویژه است. لطفا برای ثبت دیدگاه برای هر محصول موجود در این بسته شگفت انگیز، به صفحه همان محصول مراجعه کنید.',
    affiliate: {
      content: [
        {
          title: 'شرایط و قوانین استفاده از سامانه همکاری در فروش',
          description:
            'به سامانه همکار ی در فروش ژاِکت خوش آمدید.\n' +
            '\n' +
            'شما در این سامانه امکان ارائه خدمات تبلیغاتی و کسب درآمد از سایت ژاکِت را پیدا خواهید نمود.\n' +
            '\n' +
            'همکار گرامی لطفًا موارد زیر را جهت استفاده بهینه از برنامه های کاربردی سامانه همکار ی در فروش ژاِکت به دقت ملاحظه فرمایید.\n' +
            '\n' +
            'ورود شما به سامانه همکار ی در فروش و همچنین استفاده از خدمات آن به معنای آگاه بودن و پذیرفتن تمامی شرایط و قوانین استفاده از این سامانه و همچنین نحوه صحیح استفاده از آن است.\n' +
            '\n' +
            'همچنین پذیرش این قوانین به منزله ثبت نام در سیستم همکار ی در فروش ژاکت خواهد بود.',
        },
        {
          title: 'سامانه همکار ی در فروش ژاکت',
          description:
            'سامانه همکاری در فروش ژاکت (که در این قرارداد به اختصار سامانه همکاری نامیده می‌شود.)، سامانه‌ای است که بر روی وب سایت ژاکِت به آدرس www.zhaket.com ایجاد می‌شود و شامل وب سایت، داده ها و طراحی های موجود در آن می‌باشد. عضو شدن در آن به شما امکان می دهد که از وب سایت ، شبکه اجتماعی، و نرم افزارهای آنلاین خود (که در این شرایط و قوانین به اختصار "وبسایت" نامیده می شوند.) درآمدزایی بنمایید. اشخاصی که در این سامانه عضو شده و نام کاربری و رمز عبور دریافت نمایند، همکار/ همکاران نامیده می شوند.\n' +
            '\n' +
            'ژاکِت در سامانه همکاری در فروش، یک لینک اختصاصی که برای هر فرد به صورت مجزا تعریف می‌شود، در اختیارتان قرار می دهد و لینک مذکور باید به طور کاملا صحیح و از فرمت لینک تگ شده‌ی مخصوصی که ژاکِت تهیه نموده و مطابق این شرایط و قوانین تنظیم شده است، باشد. این لینک در واقع محصولات پیشنهادی ژاکِت را معرفی می نماید که مطابق با موضوع فعالیت "وبسایت" شما، محصولات مرتبط را در بر می گیرد. شما با قرار دادن لینک اختصاصی خود در "وب سایت" تان، می توانید درآمد زایی کنید.',
        },
        {
          title: 'نحوه ی درآمد زایی',
          description:
            'هنگامی که مشتریان ما بر روی لینک اختصاصی همکار کلیک می کنند تا محصولی را که در سایت ژاکِت پیشنهاد شده خریداری نمایند، همکار برای کلیه‌ خریدهایی که تا ۷ روز پس از ورود با لینک همکاری در فروش ایشان توسط این کاربر انجام شود، می‌تواند ۷٪ ارزش سبد خرید خریداری شده را به عنوان سهم همکاری در فروش دریافت نماید. ژاکِت می تواند برای سهولت در انجام تبلیغات محصولات خود، اطلاعات، تصاویر، متن، فرمت لینک، محتوای بازاریابی و دیگر موارد در این رابطه را برای شما فراهم نماید.\n' +
            '\n',
        },
        {
          title: 'ارتباطات الکترونیکی',
          description:
            'شما پس از تایید این قوانین به صورت کاملا الکترونیکی، لینک همکاری در فروش خود را دریافت کرده و می‌توانید فعالیت خود را آغاز کنید.\n' +
            '\n' +
            'همکاران می‌بایست به منظور دریافت درآمد و یا وجوه خود، مشخصات کامل خود شامل نام، نام خانوادگی و کد ملی را مطابق با اسناد معتبر هویتی ، شماره تماس و اطلاعات کامل حساب بانکی خود را در قسمت حساب کاربری خود وارد نمایند، در غیر اینصورت و در صورت مشاهده مغایرت و عدم تطابق اطلاعات، ژاکِت مسئولیتی در قبال تبعات آن نخواهد داشت.\n' +
            '\n' +
            'همچنین مشخصات، شماره حساب، آدرس ایمیل و تلفن‌هایی که همکاران در پروفایل خود ثبت می‌کنند، تنها اطلاعات رسمی و مورد تایید همکار است و تمام مکاتبات، پاسخ‌ ها و واریزهای شرکت از طریق آن ها و به آن ها صورت می‌گیرد.',
        },
        {
          title: 'سیاستهای رعایت حریم شخصی',
          description:
            'ژاکِت به اطلاعات خصوصی همکارانی که از خدمات سامانه استفاده می‏‌کنند، احترام گذاشته و از آن محافظت می‏‌کند.\n' +
            '\n' +
            'ژاکِت متعهد می‏‌شود در حد توان از حریم شخصی شما دفاع کند و در این راستا، تکنولوژی مورد نیاز برای هرچه مطمئن‏‌تر و امن‏‌تر شدن استفاده شما از سایت را، توسعه دهد اما در صورت بروز مشکلات غیرقابل شناسایی یا مشکلات فنی، ژاکت نهایت تلاش خود را برای رفع مشکل در اسرع وقت و در کوتاه ترین زمان ممکن انجام خواهد داد. در این میان چنانچه بخشی از اطلاعات همکاران در اختیار افراد سودجو قرار گیرد، باتوجه به بروز مشکل خارج از اختیارات ژاکت ، همکار حق اعتراض ندارد و ژاکت به نمایندگی از همکار نهایت تلاش خود را برای بازپس‌گیری حقوق و خسارت احتمالی وارده به همکار را از جانب افراد سودجو به انجام خواهد رساند. قابل ذکر است در شرایط بروز مشکلات، رفع مشکل و مسدود سازی دسترسی افراد سودجو در اولین اولویت کاری قرار دارد.\n' +
            '\n' +
            'همه مطالب در دسترس از طریق هر یک از خدمات ژاکِت، مانند متن، گرافیک، آرم، آیکون دکمه، تصاویر، ویدئوهای تصویری، موارد قابل دانلود و کپی، داده‌ها و کلیه محتوای تولید شده توسط ژاکِت تحت حمایت حقوق مالکیت فکری ژاکِت محسوب می‌باشد و حق استفاده و نشر تمامی مطالب موجود متعلق به ژاکِت است و هرگونه استفاده غیر مجاز، حق پیگرد قانونی را برای ژاکِت محفوظ می‏‌دارد. علاوه بر این علائم تجاری ثبت شده نیز در انحصار ژاکِت است و هر گونه استفاده با مقاصد تجاری پیگرد قانونی دارد.',
        },
        {
          title: 'شرایط پذیرش همکاران',
          description:
            'همکار می‌بایست به منظور درج لینک ژاکت در “وبسایت” خود حتما موارد زیر را رعایت کند :\n' +
            '\n' +
            '•  از نظر طراحی و گرافیک وب، در سطح مطلوب باشد.\n' +
            '\n' +
            '•  دارای پاپ آپ یا نمایش بیش از حد تبلیغات نباشد.\n' +
            '\n' +
            '•  دارای مطالب و محتوای کافی و آرشیو باشد.\n' +
            '\n' +
            '•  حاوی اطلاعات ناقص و یا ناهمگون نباشد.\n' +
            '\n' +
            '•  حاوی مطالب غیر اخلاقی و خلاف مصلحت عمومی نباشد.\n' +
            '\n' +
            '•  فعالیت سیاسی نداشته باشد.\n' +
            '\n' +
            '•  مطابق با قوانین و مقررات حاکم باشد.\n' +
            '\n' +
            "•  دارای مطالبی که باعث گمراهی افراد برای ورود به سایت ژاکِت شود، نباشد. به عنوان مثال حاوی لینکی با عنوان 'برای ورود به سایت اصلی ژاکِت اینجا کلیک کنید' نباشد.\n" +
            '\n' +
            '•  همکاران موظف اند از آوردن نام ژاکِت در آدرس, عنوان و لوگوی رسانه ی خود(سایت یا شبکه‌ی اجتماعی) خودداری نمایند.\n' +
            '\n' +
            '•  همکاران می‌بایست در جریان باشند که فعالیت بهینه سازی گوگل روی کلمه ژاکِت ممنوع است.\n' +
            '\n' +
            'تبصره: لازم به توضیح است که موارد بالا به تشخیص ژاکِت مورد بررسی می گیرد و در صورت مشاهده، دسترسی کاربر از سیستم همکاری در فروش ژاکت گرفته خواهد شد و همکار حق اعتراض برای تجدید نظر را نخواهد داشت. همکار با آگاهی از این امر اقدام به دریافت لینک نموده و نمی تواند ادعای ورود خسارت را داشته باشد.',
        },
        {
          title: 'شرایط همکار ی با سامانه همکار ی در فروش',
          description:
            '1.  همکاران اقرار و اظهار می دارند که وبسایت، اپلیکیشن و یا شبکه اجتماعی خود را مطابق با قوانین جمهوری اسلامی ایران و شرایط این قرارداد و همچنین تعهدات خود با اشخاص ثالث اداره می کنند .\n' +
            '\n' +
            '2.  همکاران مسئولیت هر گونه هزینه های ناشی از ورود و عضویت در "سامانه همکاری در فروش" و انجام بازاریابی بر سایت خود را مانند هزینه های پرداخت اینترنت، خرید و تمدید دامنه (Domain) و دیگر هزینه های مرتبط با نگهداری وب سایت را شخصا به عهده دارند و ژاکِت هیچ گونه مسئولیتی در پرداخت آن ها نداردDomain) و دیگر هزینه های مرتبط با نگهداری وب سایت را شخصا به عهده دارند و ژاکِت هیچ گونه مسئولیتی در پرداخت آن ها ندارد.\n' +
            '\n' +
            '3.  حق واگذاری این قرارداد، لینک اختصاصی خود، سامانه خود (نام کاربری و رمز) و محتواهای تولید شده توسط ژاکِت را به اشخاص ثالث جزئا و کلا ندارند و نخواهند داشت.\n' +
            '\n' +
            '4.  ژاکِت حق تغییر شرایط این قرارداد را داشته و این تغییرات و همچنین اطلاعیه ها و ابلاغیه ها توسط ژاکِت در وبسایت ژاکِت اعلام می شوند که به منزله‌ی ابلاغ به همکاران می‌باشد. همکاران متعهد هستند تمامی اطلاعیه ها را با دقت مطالعه نموده و طبق آن عمل نمایند. این تغییرات به طور خودکار پس از قرار گرفتن روی وبسایت ژاکِت به منزله ی اصلاحیه با الحاقیه این قرارداد محسوب می‌شود و نیاز به هیچگونه تشریفات دیگری جهت امضا و ابلاغ نمی‌باشد.\n' +
            '\n' +
            '5.  همکاران متعهد هستند بدون اخذ تایید کتبی ژاکِت (و یا اخذ تایید به صورت اتوماسیون در سامانه) از برند و علائم تجاری ژاکِت در ایمیل های خود URL ، Source Code 5. همکاران متعهد هستند بدون اخذ تایید کتبی ژاکِت (و یا اخذ تایید به صورت اتوماسیون در سامانه) از برند و علائم تجاری ژاکِت در ایمیل های خود URL ، Source Code نامه ها، سایت خود (به جز محدوده ی لینک اختصاصی) استفاده ننمایند. همکاران متعهد هستند این اطمینان را حاصل نمایند که در اطلاعات و محتواهایی که در منظر عموم قرار می دهند، شبهه ی ارتباطی جز همکاری در سامانه همکاری در فروش (از جمله مشارکت، نمایندگی و غیره) را ایجاد ننمایند. در غیر این صورت مسئول جبران هرگونه خسارتی هستند که از این بابت به اشخاص ثالث،(یا ژاکت) وارد می‌شود.\n' +
            '\n' +
            '6.  همکاران متعهد هستند بدون اخذ تایید کتبی ژاکِت (و یا اخذ تایید به صورت اتوماسیون در سامانه) از محتوای لینک اختصاصی خود برای تشویق مشتریان به شرکت در قرعه کشی، مسابقات و ارائه محصولات در قالب هدیه و اخذ هرگونه وجه از خریدار برای اهداف فوق که خود طراحی نموده اند، استفاده ننمایند. در صورت عدم رعایت این تعهد مسئولیت های قانونی و پاسخگویی در مراجع قانونی بر عهده همکار می‌باشد.\n' +
            '\n' +
            '7.  همکاران در صورت لزوم باید جایگاه و ارتباط خود را با ژاکِت با عنوان "عضویت در سامانه همکاری در فروش" واضح و روشن اعلام بدارند. همچنین در سایت، اپلیکیشن، شبکه های مجازی خود، دیگران و یا دیگر اماکن عمومی آنلاین و آفلاین به هیچ عنوان، حق ندارند اظهار نمایند که با ژاکِت هرگونه ارتباط دیگری به جز عنوان مذکور در فوق، همچون رابطه نمایندگی، اسپانسرشیپی، حمایت گری، جوینت ونچری و یا هر ارتباط غیر واقعی دیگری دارند. در صورت وجود چنین ادعاهایی، همکاران شخصا مسئول جبران خساراتی که از این حیث به شرکت ژاکِت، برند ژاکِت و اعتبار ژاکِت وارد شود، می‌باشند. در این موارد ژاکِت می تواند به صلاحدید خود نسبت به ارزیابی میزان خسارات و کسر آن‌ها از مطالبات همکاران اقدام نماید.‌باشد.\n' +
            '\n' +
            '8.  همکاران متعهد می‌گردند از روش‌هایی مانند popup یا نمایش بیش از حد تبلیغات در هر صفحه خودداری نموده و از عباراتی نظیر "برای حمایت از ما روی تبلیغات/لینک کلیک کنید" و یا موارد تشویق کننده از جمله برنامه‌های جایزه یا هر روش دیگری که منجر به افزایش غیرواقعی تعداد کلیک می‌شود، استفاده نکند . در صورت مشاهده نقض این تعهد، ژاکِت می تواند علاوه بر الزام همکار به جبران خسارات، دسترسی به پنل را برای ناقض تعهد منع نماید و همکار در این خصوص ادعا و اعتراضی نخواهد داشت.‌باشند. در این موارد ژاکِت می تواند به صلاحدید خود نسبت به ارزیابی میزان خسارات و کسر آن‌ها از مطالبات همکاران اقدام نماید.\n' +
            '\n' +
            '9.  همکاران متعهد می گردند کدهای اختصاصی خود را در داخل آی فریم (Iframes) های وبسایت خود قرار ندهند.\n' +
            '\n' +
            '10.  همکاران متعهد هستند در صورتی که برای بدست آوردن اطلاعاتی مانند آدرس پروتکل اینترنتی مشتریان، سیستم عامل رایانه، نوع مرورگر، ترافیک و دریافت مدخل ورودی آن ها به وبسایت، از هرگونه نرم افزار و یا برنامه ای استفاده می کنند، آن را به اطلاع مشتری برسانند. ژاکِت در این خصوص هیچگونه مسئولیتی ندارند.\n' +
            '\n' +
            '11.  ژاکِت در خصوص موارد ذیل در مقابل همکاران و اشخاص ثالث هیچگونه مسئولیتی ندارد و نخواهد داشت:' +
            '\n' +
            'أ‌. هرگونه آسیب، نقض، خسارت، اشتباه و یا هرگونه ترافیکی که بر روی وب سایت همکاران ایجاد شود.\n' +
            '\n' +
            'ب‌. هرگونه مطلبی که دارای کپی رایت و حقوق اشخاص ثالث است و همکار بدون اجازه‏‌ی صاحب اثر آن را در وبسایت خود منتشر نموده است.\n' +
            '\n' +
            'ت‌. هرگونه تلاشی که همکاران برای نفوذ به وبسایت دیگران و یا تخریب اطلاعات موجود در وبسایت آن ها ( هک کردن) انجام دهند و همچنین هرگونه انتقال ویروس یا استفاده از هرگونه نرم افزار دیگر با ماهیت مخرب که از طریق ابزارهای ارتباطی وبسایت انجام شود.\n' +
            '\n' +
            'ث‌. هرگونه محتوا، قالب سایت و یا هرچیزی که به هر نحوی در وبسایت همکاران ظاهر می شود و به نمایش در می آید که شامل موارد ذیل و هرمورد دیگری در این خصوص می‌باشد:\n' +
            '\n' +
            '•  ارائه مطالبی که تلویحا یا تصریحا شامل مفاهیمی استهزاء کننده، تحقیر کننده یا توهین‌آمیز نسبت به هر نژاد، قوم، مذهب، ملیت و جنسیت باشد.\n' +
            '\n' +
            '•  ارائه مطالبی که بطور مستقیم یا غیرمستقیم فعالیت‌های غیرقانونی و غیراخلاقی نظیر قمار آنلاین، پورنوگرافی، تصاویر مروج فساد و مطالب خارج از نزاکت و برخلاف عفت عمومی را ترویج نماید.\n' +
            '\n' +
            '•  ارائه مطالبی از قول منابع علمی، بدون این که از سوی منابع موثق علمی تایید شده باشد\n' +
            '\n' +
            '•  ارائه مطالبی که حاوی ادعاهای غیرقابل اثبات و مطالب گمراه کننده باشد.\n' +
            '\n' +
            'ج‌.در صورتی که کارمندان و یا افراد وابسته به همکاران از هر حیث، نمایندگی، شراکت و... موجب نقض قوانین جمهوری اسلامی ایران و یا تعهدات ناشی از این قرارداد یا دیگر قراردادها با اشخاص ثالث بشوند\n' +
            '\n' +
            'ح‌. در صورت بروز هرگونه اختلال یا قطع شبکه اینترنتی و دسترسی همکاران در موارد فورس ماژور.\n' +
            '\n' +
            '12.  همکاران توافق و اظهار می دارند که ژاکِت در خصوص موارد ذیل مجاز است و حق هرگونه ادعا یا مطالبه خسارتی را از خود سلب می نمایند :\n' +
            '\n' +
            'أ‌. ژاکِت می تواند وبسایت، همکاران را مانیتور و نظارت نمایند، اطلاعات مربوط به سامانه همکاری را ثبت و ضبط نمایند و یا اطلاعات آن را به اهداف مختلفی از جمله ارائه آمار و اطلاعات، نمونه برتر و... انتشار دهند .\n' +
            '\n' +
            'ب‌. ژاکِت می تواند بر وبسایت، همکاران جهت اطمینان از مطابقت آن ها با قوانین و مقررات و تعهدات این قرارداد نظارت نمایند .\n' +
            '\n' +
            "ت‌. ژاکِت می تواند سایت یا اپلیکیشنی (روش دیگری) را مطابق یا شبیه با وبسایت های همکاران احداث و یا اداره نمایند و به جز موانع قانونی، از حیث وجود قرارداد و ارتباط در 'سامانه همکاری در فروش'، ممانعتی برای ژاکِت وجود ندارد.\n" +
            '\n' +
            "ث‌. سیاست های انجام تبلیغات اعم از هماهنگی در محتوا، عکس، قالب و غیره در' سامانه همکاری در فروش' قرار خواهد گرفت که همکاران موظف به رعایت این موارد هستند .\n" +
            '\n' +
            'ج‌. تعهدات اختصاصی همکاران و یا سیاست های همکاری در فروش ممکن است در طی مدت قرارداد تغییر نماید که در سامانه به اطلاع همکاران خواهد رسید و از این حیث، همکاران حق هیچگونه اعتراضی را ندارند و در صورت عدم موافقت با تغییرات انجام شده می توانند با توجه به شرایط خاتمه قرارداد، به قرارداد خود خاتمه دهند .\n' +
            '\n' +
            'ح‌. همکاران اظهار و اعلام می دارند که فی ما بین ژاکِت و همکاران هیچ گونه ارتباطی اعم از نمایندگی، کارگر و کارفرمایی، فرانشیزی، جوینت ونچری، نماینده ی فروش و غیره وجود نداشته و نخواهد داشت.\n' +
            '\n' +
            'خ‌. در صورتی که همکاران هر یک از شروط این قرارداد و یا شروط هر قرارداد دیگری را (در خصوص هر موضوعی) که با ژاکِت دارند نقض نمایند، ژاکِت می تواند جهت جبران خسارات و یا حقوق دیگری که در قراردادها تعریف شده است، راسا پرداخت مبالغی که می بایست از حیث این قرارداد به همکاران پرداخت نماید را متوقف نموده و یا دستور توقف آن را بدهد و نقض قراردادی همکاران چه به صورت مستقیم چه غیر مستقیم، به این قرارداد مرتبط باشد را، از مطالبات، جبران خسارات بنماید. در این خصوص، همکاران حق هرگونه اعتراض و ادعایی را از خود سلب می نمایند. بدیهی است چنانچه خسارات وارده بیش از مطالبات باشد، مبلغ مازاد نیز قابل مطالبه خواهد بود.\n' +
            '\n' +
            'د‌. چنانچه همکار مرتکب یکی از تخلفات مندرج در این توافقنامه شود و یا خارج از تعهدات این توافقنامه عمل نماید یا در صورت وصول هرگونه دستور از سوی مقامات دولتی و قضایی، ژاکِت می‌تواند حساب کاربری همکار را بدون اخطار قبلی مسدود نماید. همکار جهت فعال‌سازی مجدد وبسایت خود باید اسناد مثبته خود را مبنی بر قانونی بودن فعالیت‌هایش که با اصول کلی وبسایت ژاکِت هماهنگی داشته باشد، ظرف ده روز ارائه نماید در صورت عدم ارائه مدارک در مدت مذکور و یا چنانچه مدارک ارسالی به تشخیص ژاکِت، بر قانونی بودن فعالیت‌های همکار دلالت نکند، تمامی درآمد متخلف از حساب کاربری همکار کسر می‌شود. همکار می‌تواند بعد از گذشت یک ماه نسبت به درخواست رفع مسدودیت از وبسایت خود اقدام نماید. در هر صورت از این حیث مسئولیتی متوجه ژاکِت نمی‌باشد.\n' +
            '\n' +
            '13. طرف دوم اقرار می نماید که کار موضوع قرارداد حاضر را به تنهایی و شخصا اجرا می نماید و طرف دوم دارای هیچگونه کارمند یا کارگر تحت استخدام رسمی یا پیمانی یا قراردادی یا ... نبوده و نیز دارای هیچگونه کارگاه، شرکت، واحد صنفی و ... جهت کسب درآمد و انجام موضوع قرارداد حاضر نمی باشد.\n' +
            '\n' +
            '14. کلیه ابزارهای لازم جهت انجام کار موضوع قرارداد حاضر توسط طرف دوم تهیه و تدارک دیده شده و ژاکت در این خصوص هیچگونه مسئولیتی ندارد.\n' +
            '\n' +
            '15. با توجه به اینکه طرف دوم به تنهایی و بدون هیچگونه تبعیت دستوری و اقتصادی، موضوع قرارداد را اجرا می نماید، قرارداد حاضر و چارچوب همکاری بین ژاکت و طرف دوم مشمول مفاد قوانین کار و تامین اجتماعی نخواهد بود.\n' +
            '\n' +
            '16. در خصوص مسائل مالیاتی هر یک از طرفین مسئول تمام فعالیت‌های خود، اظهار و پرداخت مالیات‌های مربوطه هستند. ژاکت در خصوص ثبت اسناد و ارائه گزارشات به طور کامل از قوانین مالیاتی کشور پیروی می‌کند و هیچ مسئولیتی در قبال نحوه ارائه گزارش‌های مالیاتی طرف دوم برعهده ندارد. هرگونه کسورات قانونی مطابق دستورالعمل‌ها و تعرفه‌های رسمی کشور اعم از این‌که در زمان یا بعد از انعقاد قرارداد وضع شود، در محاسبات و صورت‌حساب‌ها لحاظ شده و به مراجع ذی‌ربط پرداخت خواهد شد.',
        },
        {
          title: 'شرایط پرداخت',
          description:
            'درآمد همکار بر اساس شروط پرداخت و نرخ های اعلام شده در ژاکِت بعد از هر تراکنش محاسبه و به کیف پول همکاری در فروش همکار واریز می‌گردد، لیکن واریز آن به شماره حساب همکار پس از رسیدن به حد نصاب مشخص شده و در‌خواست واریز از جانب همکار (در پنل خود، درخواست تسویه صورت حساب) به صورت ماهانه انجام می‌شود به این صورت که درخواست های هر ماه در اول تا پنجم ماه بعد انجام خواهند شد. تایید پرداخت ها هم به صورت اتوماتیک طبق همین رویه انجام می‌شود. ژاکِت در هر زمانی حق دارد که شرایط پرداخت را بر اساس نرخ‌های جدید اصلاح نماید. ادامه فعالیت پس از تغییر نرخ‌ها، به منزله تایید ضمنی همکار می‌باشد.\n' +
            '\n' +
            'درآمد سایت های همکار از انجام خرید ها تا قبل از رسیدن مبلغ موجودی همکار به حداقل مبلغ معین شده در سامانه همکاری (قسمت تسویه حساب) نزد ژاکِت به صورت امانت تا هر زمانی که مبلغ به حداقل برسد، باقی خواهد ماند.\n' +
            '\n' +
            'تبصره 1: با توجه به اینکه کمیسیون گروه های مختلف محصول بر اساس قوانین داخلی ژاکِت و عوامل تاثیرگذار بر قیمت و با در نظر گرفتن حقوق فروشندگان و توسعه دهندگان ژاکِت تعیین شده است و این درصد ها بر روی همه ی محصولات آن دسته بندی اعمال می‌شود، حداکثر کمیسیون پرداختی برای هر سبد خرید مبلغ صد هزارتومان می‌باشد.\n' +
            '\n' +
            'تبصره 2: با توجه به اینکه کمیسیون فروش در قبال ترغیب مشتری برای خرید محصول از ژاکِت پرداخت می‌شود، در صورت استفاده مشتری از کد تخفیف برای ثبت سفارش، به جهت اعمال اثر کد تخفیف در خرید مشتری، محاسبه مبلغ کمسیون همکار از مبلغ پرداختی نهایی مشتری انجام خواهد شد.\n' +
            '\n' +
            'تبصره 3: توسعه دهندگان و فروشندگان ژاکت اجازه درج لینک همکاری در فروش خود و یا دیگران را در توضیحات محصولات، پیش نمایش، فایل آموزش محصول، لینک‌های درج شده در داخل محصولات قبل و پس از نصب، دیدگاه محصولات، تیکت های پشتیبانی و هر کانال داخلی ژاکت را نداشته و در صورت تخلف از این مسئله ژاکت این اجازه را دارد دسترسی همکار مربوط به آن لینک را مسدود کرده و درآمد حاصل از این همکاری در فروش را از فروشنده کسر کند.\n' +
            '\n',
        },
        {
          title: 'حل اختلاف',
          description:
            'طرفین تصریح و توافق دارند که محل انعقاد و اجرای قرارداد شهر تهران می باشد. در صورت بروز اختلاف، مرجع صالح، دادگستری های شهر تهران می باشد. این بند مانع از توافق طرفین، در هر زمانی، برای حل دوستانه و مسالمت آمیز موضوعات نمی باشد.\n' +
            '\n',
        },
        {
          title: 'قوه قهریه',
          description:
            '\n' +
            'تمامی شرایط و قوانین مندرج، در شرایط عادی قابل اجرا است و در صورت بروز هرگونه از موارد قوه قهریه، ژاکِت هیچ گونه مسئولیتی ندارد.',
        },
      ],
      readAndAcceptRules: 'شرایط همکاری در فروش ژاکت را خواندم و آن را میپذیرم.',
      acceptAndGetLink: 'پذیرش قوانین و دریافت لینک همکاری',
    },
  },
  cart: {
    easyAccessToPurchase: 'دسترسی آنی به فایل محصول بعد از پرداخت',
    productsPrice: 'قیمت محصولات',
    productDiscount: 'تخفیف محصول',
    checkoutAmount: 'مبلغ قابل پرداخت',
    doYouHaveDiscount: 'کد تخفیف دارید؟',
    discount: 'کد تخفیف',
    submitCode: 'ثبت کد',
    cartIsEmpty: 'سبد خرید شما خالی است!',
    submitAndPay: 'ثبت و پرداخت',
    doYouWantToRemoveTheProduct: 'آیا از حذف محصول اطمینان دارید؟',
    accessToProductFileForLifeTime: 'با حذف محصول امکان بازگردانی وجود ندارد.',
    yupRemoveIt: 'بله پاک شود',
    toContinuePurchaseYouHaveToFirstlyReadAndAcceptRules:
      'برای ادامه‌ی خرید لازم است شرایط و قوانین را مطالعه کرده و بپذیرید',
    voucherSuccessfullyAdded: 'کد تخفیف با موفقیت اعمال شد',
    voucherSuccessfullyRemoved: 'کد تخفیف با موفقیت حذف شد',
    removeVoucher: 'حذف کد',
    productSuccessfullyAdded: 'محصول باموفقیت به سبد اضافه شد',
    enterYourCode: 'کد تخفیف را وارد کنید',
    extraSupport: '6 ماه پشتیبانی بیشتر',
    installation: 'نصب قالب',
    extraLicenseSupport: 'تکرار خرید',
    wallet: 'کیف پول',
  },
  content: {
    contact: {
      meta_title: 'تماس با ما ژاکت، راه های ارتباطی',
      meta_description:
        'در این صفحه اطلاعات تماس و راه‌های ارتباطی با ژاکت، همچنین نقشه ژاکت قرار گرفته است',
      address: 'تهران - خیابان پاسداران - ابتدای اختیاریه جنوبی - پلاک 2- واحد 10',
      postalCode: 'کد پستی',
      email: 'ایمیل',
      phone_communication_with_zhaket: 'ارتباط تلفنی با ژاکت',
      note_contact_us_first_part:
        'دقت کنید این سیستم برای تماس با ژاکت است جهت پشتیبانی محصول به بخش  ',
      pannel_ticket: 'تیکت پنل',
      note_contact_us_second_part: 'خود بروید',
      unit_one: 'داخلی یک',
      support_unit: 'واحد پشتیبانی',
      unit_two: 'داخلی دو',
      consulting_unit: 'دریافت مشاوره خرید ',
      unit_three: 'داخلی سه',
      development_unit: 'امور توسعه دهندگان',
      unit_four: 'داخلی چهار',
      contact_with_zhaket: 'ارتباط با ژاکت سرویس',
      form: {
        name: 'نام',
        your_message: 'پیام شما',
        send: 'ارسال',
        relevant_unit: 'واحد مربوطه',
        send_message: 'ارسال پیام',
      },
    },
    aboutUs: {
      meta_title: 'درباره ما',
      meta_description:
        'محصولات اورجینال، ایرانی و فارسی سازی شده وردپرس را با ۶ ماه پشتیبانی رایگان، تضمین اصالت و کیفیت ژاکت و ۶ ماه ضمانت بازگشت وجه خریداری کنید.',
      header_title: 'مرجع وردپرس فارسی',
      header_subtitle: 'و رهبر بازار اولین پلتفرم ارائه دهنده خدمات ',
      login_to_zhakhet: 'ورود به ژاکت',

      extra_products: 'سایر محصولات ژاکت',
      Successful_purchase: 'خرید موفق',
      million: (count) => `+${count} میلیون`,
      membership: 'کاربر عضو',
      active_developer: 'توسعه دهنده فعال',
      who_from: 'کسانی که از',
      zhaket_services_used: 'خدمات ژاکت استفاده کردند',
      benefits: {
        benefits_and_services: 'مزایا و خدمات ژاکت',
        why_we_are_the_best: 'ما چرا بهترینیم؟',
        firstCard: {
          subtitle: 'ماه گارانتی بازگشت وجه',
          description: 'شما فرصت دیده شدن توسط تعداد قابل توجهی مشتری را خواهید داشت.',
        },
        secondCard: {
          title: 'به روزرسانی',
          subtitle: 'دايم محصولات',
          description: 'شما فرصت دیده شدن توسط تعداد قابل توجهی مشتری را خواهید داشت.',
        },
        thirdCard: {
          title: 'کیفیت',
          subtitle: 'بالا و تضمین ژاکت',
          description: 'شما فرصت دیده شدن توسط تعداد قابل توجهی مشتری را خواهید داشت.',
        },
      },
      history: {
        zhaket_history: 'تاریخچه ژاکت',
        first_spark: 'اولین جرقه',
        establishment: 'تاسیس ژاکت',
        title: {
          more_than: ' بیش از ',
          two_thousand_products: ' ۲۰۰۰ محصول',
          for_you: 'برای شروع سایت شما',
        },

        the_first_version: 'نسخه اول ژاکت',
        the_second_version: 'نسخه دوم ژاکت',
        new_site: 'سایت جدید',
        firstVersion: 'رونمایی نسخه اختصاصی ژاکت',
        changeBlogUI: 'تغییر رابط کاربری وبلاگ',

        year_1403: {
          note: {
            title: 'نسخه جدید ژاکت سرعت و کیفیت بسیار بالاتری دارد',
            subtitle: 'تمرکز ما در سال ۱۴۰۳ توسعه و رشد برند ژاکت در همه ابعاد است.',
            author: 'وحید بهرامیان',
          },
          descrption: {
            title: 'نسخه جدید ژاکت رونمایی شد',
            subtitle1: 'رونمایی از نسخه جدید Ui/Ux ژاکت',
            subtitle2:
              'برگزاری رویداد Wordpress Summit 2024 با بیش از ۷۰۰ شرکت کننده حضوری و بیش از ۳۰۰ شرکت کننده آنلاین',
          },
          month: 'مرداد',
        },
        year_1402: {
          note: {
            title: '',
            subtitle:
              'وعده ژاکت به طراحان گرافیک با سرمایه گذاری بر روی هیروباکس و رشد این محصول در ابعاد مختلف محقق شد. هیروباکس راه اندازی شد تا دسترسی طراحان، تدوین گران، اساتید و… به میلیو‌ن‌ها محتواهای گرافیکی، ویدئویی، Ui و… آسان و کم هزینه شود',
            author: 'وحید بهرامیان',
          },
          descrption: {
            title: '',
            subtitle1: 'سرمایه گذاری و توسعه هیروباکس در واحد توسعه کسب و کار',
            subtitle2:
              'برگزاری رویداد Wordpress Summit 2023 با بیش از ۴۵۰ شرکت کننده حضوری و بیش از ۲۰۰ شرکت کننده آنلاین',
            subtitle3:
              'توسعه ژاکت اینستالر به منظور راه اندازی سریع تر و با تجربه کاربری بهتر کاربران',
            subtitle4: 'برگزاری رویداد آنلاین ویژن با بیش از ۷۰۰ شرکت کننده آنلاین',
          },
          month: 'مرداد',
        },
        year_1398: {
          note: {
            title: '',
            subtitle:
              'ژاکت خلق شد تا پوششی در خور برای کسب و کارهای ایرانی و بازاری بزرگ برای توسعه دهندگان باشد. با تمام قدرت وردپرس فارسی را حمایت کردیم.',
            author: 'امیر عصاری',
          },
          descrption: {
            title:
              'از آنجایی که بهبود تجربه کاربری همواره از اولویت های ما در ژاکت است، نسخه جدید در دسترس عموم قرار گرفت.',
            subtitle1:
              'مارکت منتخب داوران در جشنواره وب و موبایل ایران در دسته "مارکت‌ها و سرویس‌های اپ استور"',

            subtitle2: 'رونمایی از نسخه جدید وب سایت ژاکت و بهبود تجربه کاربری',
          },
          month: 'آ‌ذر',
        },
        year_1395: {
          note: {
            title: '',
            subtitle:
              'ژاکت خلق شد تا پوششی در خور برای کسب و کارهای ایرانی و بازاری بزرگ برای توسعه دهندگان باشد. با تمام قدرت وردپرس فارسی را حمایت کردیم.',
            author: 'امیر عصاری',
          },
          descrption: {
            title: '',
            subtitle1: 'شروع فعالیت در قالب مارکت پلیس ',
            subtitle2: 'تغییر برند از مارکت وردپرس به ژاکت و شروع مسیر جدید فعالیت',
            subtitle3:
              'تمرکز بر جلوگیری از خروج ارز از کشور و عبور از محدودیت های خرید محصولات از مارکت های بین الملل',
            subtitle4: 'اطمینان از اصالت محصول و پشتیبانی تضمین شده برای کاربران',
            subtitle5:
              'ایجاد فرهنگ خرید نسخه اصلی خارجی توسط توسعه دهندگان به جهت افزایش امنیت محصولات و دسترسی توسعه دهنده ایرانی به پشتیبانی محصول اصلی',
            subtitle6: 'ایجاد دسته قالب HTML و اسکریپت PHP',
            subtitle7:
              'مارکت منتخب داوران و مردم در جشنواره وب و موبایل ایران در دسته "مارکت‌ها و سرویس‌های اپ استور"',
          },
          month: 'فروردین',
        },
        year_1393: {
          note: {
            title: '',
            subtitle:
              'کاربران ایرانی به محصولات فارسی سازی شده ایرانی نیاز داشتند از این رو مارکت وردپرس را راه اندازی کردیم.',
            author: 'علی حاجی محمدی موسس ژاکت',
          },
          descrption: {
            title: 'مارکت وردپرس راه اندازی شد.',
            subtitle1: 'شروع با نام مارکت وردپرس',
            subtitle2: 'تمرکز بر بومی سازی محصولات پرفروش جهانی',
          },
          month: 'بهمن',
        },
      },
    },
  },
  store: {
    description: 'بیش از ۲۰۰۰ محصول برای شروع سایت شما',
    developer_introduction: 'معرفی توسعه دهنده',
    best_seller_product: 'پرفروش‌ترین محصول',
    successful_sale: 'فروش موفق',
    average_user_rating: 'میانگین امتیاز کاربران',
    released_product: 'محصول منتشر شده ',
    the_date_of_joning: 'تاریخ پیوستن به ژاکت',
    developerDiscountCodes: 'کدهای تخفیف توسعه دهنده',
  },
  error: {
    message404: 'صفحه مورد نظر پیدا نشد',
    message403: 'محدودیت دسترسی آی پی',
    message403_vpn: 'از خارج از ایران یا با VPN به این صفحه دسترسی ندارید.',
    message500: 'ارور داخلی سرور',
    returnHome: 'بازگشت به ژاکت',
    reload: 'تلاش مجدد',
    under_construction: 'در حال بروزرسانی ژاکت هستیم',
    message429: 'تعداد درخواست‌های شما بیشتر از حد مجاز است.',
    message429_try_again: 'لطفاً چند لحظه صبر کنید و دوباره امتحان کنید.',
  },
  logo: {
    pageTitle: 'لوگو ژاکت',
    pageDescription: 'معتبرترین سامانه خرید افزونه و پلاگین فارسی',
    lightLogo: 'نسخه روشن',
    orangeLogo: 'نسخه نارنجی',
    darkLogo: 'نسخه تیره',
    downloadAllAsZipFile: 'فایل زیپ همه',
    svg: 'SVG',
    tif: 'TIF',
    png: 'PNG',
    logo: 'لوگو',
  },
  demo: {
    giveDemoFeedback: 'ثبت بازخورد پیش نمایش',
    demoReport: 'گزارش پیش نمایش',
    saveReport: 'ثبت گزارش',
    thereIsAProblemWithPreview: 'در نمایش پیش نمایش اختلال وجود دارد.',
    wasItUseful: 'این پیش نمایش برای شما مفید بوده است؟',
    yes: 'بله',
    no: 'خیر',
    optionalDescription: 'توضیحات (اختیاری)',
    feedbackSavedSuccessfully: 'بازخورد شما با موفقیت ثبت شد',
  },
};
export default localization;
